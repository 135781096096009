.App {
  background: transparent;
  width: 100%;
  height: 100vh;
}

.dark {
  color: #666666 !important;
  font-weight: bold !important;
}

.dark:hover {
  color: #dddddd !important;
  border: black 1px !important;
}

.brand-mobile {
  font-size: 30px;
}

@media screen and (min-width: 600px) {
  .brand-mobile {
    display: none;
  }
}

@media screen and (max-width: 601px) {
  .brand-desktop {
    display: none;
  }
}
