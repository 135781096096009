@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap);
body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Comfortaa";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: scale(0.5) translateY(0) rotate(0deg);
            transform: scale(0.5) translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5) translateY(-1000px) rotate(720deg);
            transform: scale(0.5) translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: scale(0.5) translateY(0) rotate(0deg);
            transform: scale(0.5) translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5) translateY(-1000px) rotate(720deg);
            transform: scale(0.5) translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}

.notes {
  width: inherit;
  height: inherit;
}

.background {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1000;
  background-size: cover;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
}

.background li {
  pointer-events: none;
  position: fixed;
  display: block;
  list-style: none;
  -webkit-align-content: center;
          align-content: center;
  left: 5%;
  width: 20px;
  height: 20px;
  bottom: -20px;
  -webkit-animation: animate 28s linear infinite;
          animation: animate 28s linear infinite;
}

.background li:nth-child(0) {
  left: 30%;
  width: 101px;
  height: 101px;
  bottom: -101px;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 76%;
  width: 125px;
  height: 125px;
  bottom: -125px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.background li:nth-child(2) {
  left: 61%;
  width: 135px;
  height: 135px;
  bottom: -135px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.background li:nth-child(3) {
  left: 22%;
  width: 162px;
  height: 162px;
  bottom: -162px;
  -webkit-animation-delay: 12s;
          animation-delay: 12s;
}
.background li:nth-child(4) {
  left: 36%;
  width: 143px;
  height: 143px;
  bottom: -143px;
  -webkit-animation-delay: 14s;
          animation-delay: 14s;
}
.background li:nth-child(5) {
  left: 70%;
  width: 157px;
  height: 157px;
  bottom: -157px;
  -webkit-animation-delay: 16s;
          animation-delay: 16s;
}
.background li:nth-child(6) {
  left: 34%;
  width: 161px;
  height: 161px;
  bottom: -161px;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.background li:nth-child(7) {
  left: 67%;
  width: 133px;
  height: 133px;
  bottom: -133px;
  -webkit-animation-delay: 25s;
          animation-delay: 25s;
}
.background li:nth-child(8) {
  left: 9%;
  width: 126px;
  height: 126px;
  bottom: -126px;
  -webkit-animation-delay: 29s;
          animation-delay: 29s;
}
.background li:nth-child(9) {
  left: 5%;
  width: 143px;
  height: 143px;
  bottom: -143px;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}
.background li:nth-child(10) {
  left: 26%;
  width: 117px;
  height: 117px;
  bottom: -117px;
  -webkit-animation-delay: 27s;
          animation-delay: 27s;
}
.background li:nth-child(11) {
  left: 1%;
  width: 137px;
  height: 137px;
  bottom: -137px;
  -webkit-animation-delay: 16s;
          animation-delay: 16s;
}
.background li:nth-child(12) {
  left: 12%;
  width: 124px;
  height: 124px;
  bottom: -124px;
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}
.background li:nth-child(13) {
  left: 50%;
  width: 118px;
  height: 118px;
  bottom: -118px;
  -webkit-animation-delay: 34s;
          animation-delay: 34s;
}
.background li:nth-child(14) {
  left: 89%;
  width: 111px;
  height: 111px;
  bottom: -111px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.background li:nth-child(15) {
  left: 60%;
  width: 134px;
  height: 134px;
  bottom: -134px;
  -webkit-animation-delay: 68s;
          animation-delay: 68s;
}
.background li:nth-child(16) {
  left: 24%;
  width: 153px;
  height: 153px;
  bottom: -153px;
  -webkit-animation-delay: 59s;
          animation-delay: 59s;
}
.background li:nth-child(17) {
  left: 50%;
  width: 123px;
  height: 123px;
  bottom: -123px;
  -webkit-animation-delay: 39s;
          animation-delay: 39s;
}
.background li:nth-child(18) {
  left: 41%;
  width: 153px;
  height: 153px;
  bottom: -153px;
  -webkit-animation-delay: 78s;
          animation-delay: 78s;
}
.background li:nth-child(19) {
  left: 80%;
  width: 155px;
  height: 155px;
  bottom: -155px;
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}
.background li:nth-child(20) {
  left: 81%;
  width: 161px;
  height: 161px;
  bottom: -161px;
  -webkit-animation-delay: 40s;
          animation-delay: 40s;
}
.background li:nth-child(21) {
  left: 50%;
  width: 144px;
  height: 144px;
  bottom: -144px;
  -webkit-animation-delay: 23s;
          animation-delay: 23s;
}

.ErrorBoundary {
  padding-top: 100px;
}

.Home .lander {
  padding: 80px 0;
  text-align: center;
  background: transparent;
}

.Home .lander h1,
h5 {
  font-weight: 700;
  font-family: "Comfortaa";
}

h5,
h5:hover {
  color: #444444;
}

.Home {
  padding-bottom: 5%;
}

.text-fade {
  overflow: hidden;
  width: 100%;
  background: rgb(27, 27, 27);
  background: linear-gradient(
    270deg,
    rgba(27, 27, 27, 0) 0%,
    rgba(40, 40, 40, 1) 10%,
    rgb(40, 40, 40) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b1b1b",endColorstr="#1b1b1b",GradientType);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.music-sheet {
  padding-bottom: 15px !important;
  font-family: "Comfortaa" !important;
}

.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
  }
  to {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

::-webkit-input-placeholder {
  font-size: 13px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}

.form-control:focus {
  border-color: #888888;
  box-shadow: 0 0 0 0.1rem #888888;
}

.btn-custom,
.btn-custom:disabled:hover,
.btn-custom-success:disabled,
.btn-custom-success:disabled:hover,
.btn-custom-del:disabled,
.btn-custom-del:disabled:hover {
  color: #444444 !important;
  background-color: #c0c0c0 !important;
  border-color: #888888 !important;
  max-width: 20%;
  min-width: 100px;
}

.btn-custom:hover,
.btn-custom:active {
  color: #eeeeee !important;
  background-color: #adadad !important;
  border-color: #555555 !important;
}

.btn-custom-success {
  color: #444444 !important;
  background-color: #86a797 !important;
  border-color: #888888 !important;
  max-width: 20%;
  min-width: 100px;
}

.btn-custom-success:hover,
.btn-custom-success:active {
  color: #eeeeee !important;
  background-color: #769485 !important;
  border-color: #555555 !important;
}

.btn-custom-del {
  color: #444444 !important;
  background-color: #a78686 !important;
  border-color: #888888 !important;
  max-width: 20%;
  min-width: 100px;
}

.btn-custom-del:hover,
.btn-custom-del:active {
  color: #eeeeee !important;
  background-color: #947676 !important;
  border-color: #555555 !important;
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

::-webkit-input-placeholder {
  font-size: 13px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}

.form-control:focus {
  border-color: #888888;
  box-shadow: 0 0 0 0.1rem #888888;
}

.NotFound {
  padding-top: 100px;
}

.NewMusicSheet form textarea {
  height: 300px;
  font-size: 1.5rem;
}

::-webkit-input-placeholder {
  font-size: 15px !important;
  padding-top: 10px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 15px !important;
  padding-top: 10px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 15px !important;
  padding-top: 10px;
}

.MusicSheet form textarea {
  height: 300px;
  font-size: 1.5rem;
  background: transparent;
}

.App {
  background: transparent;
  width: 100%;
  height: 100vh;
}

.dark {
  color: #666666 !important;
  font-weight: bold !important;
}

.dark:hover {
  color: #dddddd !important;
  border: black 1px !important;
}

.brand-mobile {
  font-size: 30px;
}

@media screen and (min-width: 600px) {
  .brand-mobile {
    display: none;
  }
}

@media screen and (max-width: 601px) {
  .brand-desktop {
    display: none;
  }
}

