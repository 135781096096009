@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap");

.Home .lander {
  padding: 80px 0;
  text-align: center;
  background: transparent;
}

.Home .lander h1,
h5 {
  font-weight: 700;
  font-family: "Comfortaa";
}

h5,
h5:hover {
  color: #444444;
}

.Home {
  padding-bottom: 5%;
}

.text-fade {
  overflow: hidden;
  width: 100%;
  background: rgb(27, 27, 27);
  background: -moz-linear-gradient(
    270deg,
    rgba(27, 27, 27, 0) 0%,
    rgba(40, 40, 40, 1) 10%,
    rgba(40, 40, 40, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(27, 27, 27, 0) 0%,
    rgba(40, 40, 40, 1) 10%,
    rgba(40, 40, 40, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(27, 27, 27, 0) 0%,
    rgba(40, 40, 40, 1) 10%,
    rgb(40, 40, 40) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b1b1b",endColorstr="#1b1b1b",GradientType);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.music-sheet {
  padding-bottom: 15px !important;
  font-family: "Comfortaa" !important;
}
